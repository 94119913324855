







































import { api } from "@/api/api";
import { ApiGetMemberOrganizationDto } from "@/api/generated/Api";
import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import BaseSheet from "@/components/shared/sheet/BaseSheet.vue";
import BaseSheetField from "@/components/shared/sheet/BaseSheetField.vue";
import BaseSheetHeader from "@/components/shared/sheet/BaseSheetHeader.vue";
import { AdminRouteNames } from "@/shared/enums/RouteNames/adminRouteNamesEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useRoute, useRouter } from "@/shared/useHelpers";
import { defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  name: "RegionOfficePage",
  components: {
    BaseSheet,
    BaseSheetHeader,
    BaseSheetField,
    BaseTooltipIconButton,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const regionOffice = ref<ApiGetMemberOrganizationDto>();
    const contentReady = ref(false);

    const navigateToMemberOrg = () => {
      router.push({
        name: AdminRouteNames.MainOffice,
        params: { mainOfficeId: route.params.mainOfficeId },
      });
    };

    onMounted(async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        regionOffice.value = (await api.organization.getMemberOrganizationAsync(+route.params.regionOfficeId)).data;
        contentReady.value = true;
      });
    });

    return {
      contentReady,
      regionOffice,
      navigateToMemberOrg,
    };
  },
});
